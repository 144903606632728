export default {
  bulk_basic: [
    { text: "none", value: "none" },
    // { text: 'Share', value: 'Share' },
    { text: "Copy", value: "Copy" },
    // { text: 'Share All', value: 'Share_all' },
    { text: "Copy All", value: "Copy_all" },
    { text: "Delete", value: "Delete" },
    { text: "Delete All", value: "DeleteAll" },
    { text: "Set Tag", value: "setTag" },
    { text: "Add to Buffer", value: "buffer" },
    { text: "Deploy Item", value: "deploy_item" },
    { text: "Export To CVS", value: "ExportToCVS" },
    { text: "Mass Data Update", value: "mdu" },
    { text: "Columns", value: "columns" },
    // { text: 'Add to Buffer', value: 'buffer' },
    /* { text: 'Deploy', value: 'Deploy' }, */
  ],
  bulk_trash: [
    { text: "none", value: "none" },
    { text: "Restore", value: "Restore" },
    { text: "Complete Delete", value: "completeDelete" },
  ],
  bulk_deploy_dev: [
    { text: "none", value: "none" },
    { text: "Deploy Item", value: "deploy_item" },
    { text: "Activate/Diactivate", value: "diactivate" },
    { text: "Export To CVS", value: "ExportToCVS" },
  ],
  bulk_deploy_stage: [
    { text: "none", value: "none" },
    { text: "Deploy Item", value: "deploy_item" },
    { text: "Activate/Diactivate", value: "diactivate" },
    { text: "Export To CVS", value: "ExportToCVS" },
  ],
  bulk_deploy_prod: [
    { text: "none", value: "none" },
    { text: "Activate/Diactivate", value: "diactivate" },
    { text: "Export To CVS", value: "ExportToCVS" },
  ],
  bulk_draft: [
    { text: "none", value: "none" },
    { text: "Share", value: "Share" },
    { text: "Copy", value: "Copy" },
    { text: "Share All", value: "Share_all" },
    { text: "Copy All", value: "Copy_all" },
    { text: "Delete", value: "Delete" },
    { text: "Delete All", value: "DeleteAll" },
    { text: "Set Tag", value: "setTag" },
    /* { text: 'Deploy', value: 'Deploy' }, */
  ],
  cash_list_manager: [
    { text: "none", value: "none" },
    { text: "Update Type", value: "update_type" },
    { text: "Update All", value: "update_all" },
    { text: "Delete Type", value: "delete_type" },
    { text: "Delete ALL", value: "delete_all" },
  ],
  cash_list_client: [
    { text: "none", value: "none" },
    { text: "Delete ALL DEV", value: "delete_all_dev" },
    { text: "Delete ALL STAGE", value: "delete_all_stage" },
    { text: "Delete ALL PROD", value: "delete_all_prod" },
  ],

  cash_list_schema: [
    { text: "none", value: "none" },
    { text: "Update All", value: "update_all" },
    { text: "Delete ALL", value: "delete_all" },
  ],
  highRiskStatuses: [
    { text: "High", value: "high" },
    { text: "Very High", value: "very-high" },
    { text: "Medium High", value: "medium-high" },
    { text: "Medium", value: "medium" },
    { text: "Low Medium", value: "low-medium" },
    { text: "Low", value: "low" },
  ],

  _defaultTab: {
    BulkOfAction: true,
    LanguageDD: true,
    EnvDD: false,
    DeployDevButton: false,
    CardShowTemplate: false,
    CardSortButton: false,
    TableSortButton: false,
    TableSortButtonsGroup: false,
    AddNewButton: true,
    RefreshButton: true,
  },
  basic_: {
    BulkOfAction: true,
    LanguageDD: true,
    EnvDD: false,
    DeployDevButton: true,
    CardShowTemplate: false,
    CardSortButton: false,
    TableSortButton: false,
    TableSortButtonsGroup: false,
    AddNewButton: true,
    RefreshButton: true,
  },
  draft_: {
    BulkOfAction: true,
    LanguageDD: true,
    EnvDD: false,
    DeployDevButton: false,
    CardShowTemplate: false,
    CardSortButton: false,
    TableSortButton: false,
    TableSortButtonsGroup: false,
    AddNewButton: false,
    RefreshButton: true,
  },
  trash_: {
    BulkOfAction: true,
    LanguageDD: true,
    EnvDD: false,
    DeployDevButton: false,
    CardShowTemplate: false,
    CardSortButton: false,
    TableSortButton: false,
    TableSortButtonsGroup: false,
    AddNewButton: false,
    RefreshButton: true,
  },
  deploy_dev: {
    BulkOfAction: true,
    LanguageDD: true,
    EnvDD: false,
    DeployDevButton: false,
    CardShowTemplate: false,
    CardSortButton: false,
    TableSortButton: false,
    TableSortButtonsGroup: false,
    AddNewButton: false,
    RefreshButton: true,
  },
  deploy_stage: {
    BulkOfAction: true,
    LanguageDD: true,
    EnvDD: false,
    DeployDevButton: false,
    CardShowTemplate: false,
    CardSortButton: false,
    TableSortButton: false,
    TableSortButtonsGroup: false,
    AddNewButton: false,
    RefreshButton: true,
  },
  deploy_prod: {
    BulkOfAction: true,
    LanguageDD: true,
    EnvDD: false,
    DeployDevButton: false,
    CardShowTemplate: false,
    CardSortButton: false,
    TableSortButton: false,
    TableSortButtonsGroup: false,
    AddNewButton: false,
    RefreshButton: true,
  },
};
